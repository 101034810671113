<template>
  <table class="table" data-role="content">
    <slot />
  </table>
</template>

<script>
import $ from 'jquery';
import dataTable from 'datatables.net-bs4';
import { timeout } from '../../utils/common';
import { eventBus } from '../../main';

export default {
  name: 'datatable',
  props: ['options', 'dataList', 'dataKey', 'id'],
  data: function() {
    return {
      table: null,
      ct: 0,
    };
  },
  mounted: function() {
    var vm = this;
    var table = $(this.$el).dataTable(this.options);
    this.table = table;
    table.on('page.dt', function() {
      vm.$emit('page-change');
    });
    table.on('responsive-resize.dt', function() {
      console.log('responsive-resize');
    });
    table.on('search.dt', function() {
      vm.$emit('search-change');
    });

    table.on('responsive-display.dt', async function(
      e,
      datatable,
      row,
      showHide,
      update
    ) {
      if (update) {
        await timeout(1);
      }
      if (showHide) {
        row.child().mark(datatable.search(), {
          className: 'mark-red',
          synonyms: { ű: 'u' },
        });
      }
    });
    vm.UpdateTable();

    $(this.$el).on('click', 'tr', function() {
      var data = table
        .api()
        .row(this)
        .data();
      vm.$emit('rowClick', data);
    });

    eventBus.$on('dt-resize:DinamikusTable:' + this.id, this.AdjustColums);
  },
  watch: {
    dataList: function(dataList, prev) {
      let vm = this;
      var table = $(this.$el);
      var key = this.dataKey;
      if (!vm.table) {
        return;
      }
      let rowCt = table
        .DataTable()
        .data()
        .count();
      setTimeout(
        () => {
          if (!vm.table) {
            return;
          }
          table.DataTable().clear();
          table.DataTable().rows.add(dataList);
          table.DataTable().draw(false);
        },
        rowCt > 0 ? 150 : 10
      );
    },
  },
  methods: {
    async UpdateTable() {
      var vm = this;
      if (this.ct > 5) {
        return;
      }
      this.ct++;

      try {
        if (!vm.table) {
          console.log('Nincs table');
          return;
        }
        vm.table.DataTable().rows.add(vm.dataList);
        vm.table.DataTable().draw(false);
      } catch (error) {
        console.log(error);
        await timeout(200);
        await vm.UpdateTable();
      }
    },
    chunkArray: function(myArray, chunk_size) {
      var index = 0;
      var arrayLength = myArray.length;
      var tempArray = [];

      for (index = 0; index < arrayLength; index += chunk_size) {
        var myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
      }

      return tempArray;
    },
    async AdjustColums(d = 500) {
      await timeout(d);
      if (this.$el) {
        $(this.$el)
          .DataTable()
          .columns.adjust();
      }
    },
  },
  beforeDestroy: function() {
    if (!this.table) {
      console.log('Nincs table');
      return;
    }
    // $(this.$el)
    //   .DataTable()
    //   .clear()
    //   .destroy();
    this.table = null;
  },
};
</script>
